import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBFile,
  MDBInput,
  MDBRow,
} from 'mdb-react-ui-kit';
import { Container } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Paths } from '../../../../app/baseApi/baseApi';
import axios from 'axios';
import { useState } from 'react';

export default function UploadQBBanner() {
  const [viewPlanQBBanner, setViewPlanQBBanner] = useState({
    planName: '',
    planSubheading: '',
    planTagLine: '',
    planImages: null,
  });
  const [isError, setIsError] = useState({});

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setViewPlanQBBanner({ ...viewPlanQBBanner, planImages: file });
    setIsError({ ...isError, planImages: '' });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setViewPlanQBBanner({ ...viewPlanQBBanner, [name]: value });
    setIsError({ ...isError, [name]: '' });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    if (!viewPlanQBBanner.planName.trim()) {
      newErrors.planName = 'Plan name is required';
      isValid = false;
    }
    setIsError(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append('planName', viewPlanQBBanner.planName);
      formData.append('planSubheading', viewPlanQBBanner.planSubheading);
      formData.append('planTagLine', viewPlanQBBanner.planTagLine);
      formData.append('planImages', viewPlanQBBanner.planImages);
      try {
        const response = await Paths.EndpointsURL.GetPlanQBBanner;
        const record = await axios.post(response, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setViewPlanQBBanner(record.data.data);
        toast.success(record.data.message, { position: 'top-right' });
        window.location.reload();
        setViewPlanQBBanner({
          planName: '',
          planSubheading: '',
          planTagLine: '',
          planImages: null,
        });
        setIsError({});
      } catch (error) {
        console.error('Error uploading data:', error);
        // Handle error, show toast, etc.
      }
    }
  };

  return (
    <Container>
      <MDBContainer fluid>
        <MDBRow
          className="d-flex justify-content-center align-items-center"
          style={{ width: '900px' }}
        >
          <MDBCol lg="12" className="my-2">
            <h3 className="text-black mt-0 text-center">Plan QB Banner</h3>
            <form onSubmit={handleSubmit}>
              <MDBCard>
                <MDBCardBody className="px-4">
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Plan Name"
                        name="planName"
                        id="planName"
                        onChange={handleChange}
                        value={viewPlanQBBanner.planName}
                        size="lg"
                        type="text"
                      />
                      {isError.planName && <div className="text-danger">{isError.planName}</div>}
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Plan Subheading"
                        name="planSubheading"
                        id="planSubheading"
                        onChange={handleChange}
                        value={viewPlanQBBanner.planSubheading}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  {/* Sales Person and Maximum Discount */}
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBFile
                        size="lg"
                        id="planImages"
                        name="planImages"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Plan TagLine"
                        name="planTagLine"
                        id="planTagLine"
                        onChange={handleChange}
                        value={viewPlanQBBanner.planTagLine}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />

                  <hr className="mx-n2 m-0" />
                  <MDBBtn className="my-4" size="lg" type="submit">
                    Submit
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Container>
  );
}
