import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBFile,
  MDBInput,
  MDBRow,
} from 'mdb-react-ui-kit';
import { Container } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Paths } from '../../../../app/baseApi/baseApi';
import axios from 'axios';
import ReactQuill from 'react-quill';
import { useState } from 'react';

export default function UploadPlanQBPrice() {
  const [viewPlanQBBanner, setViewPlanQBBanner] = useState({
    planTabHeading: '',
    planTabParagraph: '',
    planTabSubHeading: '',
    planName: '',
    planValidity: '',
    planActualPrice: '',
    planCutPrice: '',
    planDescription: '',
    planTabImage: null,
  });
  const [isError, setIsError] = useState({});
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setViewPlanQBBanner({ ...viewPlanQBBanner, planTabImage: file });
    setIsError({ ...isError, planTabImage: '' });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setViewPlanQBBanner({ ...viewPlanQBBanner, [name]: value });
    setIsError({ ...isError, [name]: '' });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    if (!viewPlanQBBanner.planName.trim()) {
      newErrors.planName = 'Plan Name is required';
      isValid = false;
    }
    setIsError(newErrors);
    return isValid;
  };

  const handleQuillChange = (value) => {
    setViewPlanQBBanner({ ...viewPlanQBBanner, planDescription: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append('planTabHeading', viewPlanQBBanner.planTabHeading);
      formData.append('planTabSubHeading', viewPlanQBBanner.planTabSubHeading);
      formData.append('planTabParagraph', viewPlanQBBanner.planTabParagraph);
      formData.append('planName', viewPlanQBBanner.planName);
      formData.append('planValidity', viewPlanQBBanner.planValidity);
      formData.append('planActualPrice', viewPlanQBBanner.planActualPrice);
      formData.append('planCutPrice', viewPlanQBBanner.planCutPrice);
      formData.append('planDescription', viewPlanQBBanner.planDescription);
      formData.append('planTabImage', viewPlanQBBanner.planTabImage);

      try {
        const response = await Paths.EndpointsURL.GetPlanQBPriceTab;
        const record = await axios.post(response, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setViewPlanQBBanner(record.data.data);
        toast.success(record.data.message, { position: 'top-right' });
        window.location.reload();
        setViewPlanQBBanner({
          planTabHeading: '',
          planTabParagraph: '',
          planTabSubHeading: '',
          planName: '',
          planValidity: '',
          planActualPrice: '',
          planCutPrice: '',
          planDescription: '',
          planTabImage: null,
        });
        setIsError({});
      } catch (error) {
        console.error('Error uploading data:', error);
      }
    }
  };

  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || '';
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ header: 1 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['link', 'image', 'video', 'formula', 'emoji'],
    ['undo', 'redo'],
    ['fullscreen'],
    ['clean'],
  ];

  const module = {
    toolbar: toolbarOptions,
  };

  return (
    <Container>
      <MDBContainer fluid>
        <MDBRow
          className="d-flex justify-content-center align-items-center"
          style={{ width: '900px' }}
        >
          <MDBCol lg="12" className="my-2">
            <h3 className="text-black mt-0 text-center">Plan QB Price</h3>
            <form onSubmit={handleSubmit}>
              <MDBCard>
                <MDBCardBody className="px-4">
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Plan Name"
                        name="planName"
                        id="planName"
                        onChange={handleChange}
                        value={viewPlanQBBanner.planName}
                        size="lg"
                        type="text"
                      />
                      {isError.planName && <div className="text-danger">{isError.planName}</div>}
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Tab Paragraph"
                        name="planTabParagraph"
                        id="planTabParagraph"
                        onChange={handleChange}
                        value={viewPlanQBBanner.planTabParagraph}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Tab Heading"
                        name="planTabHeading"
                        id="planTabHeading"
                        onChange={handleChange}
                        value={viewPlanQBBanner.planTabHeading}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Tab SubHeading"
                        name="planTabSubHeading"
                        id="planTabSubHeading"
                        onChange={handleChange}
                        value={viewPlanQBBanner.planTabSubHeading}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Plan Validity"
                        name="planValidity"
                        id="planValidity"
                        onChange={handleChange}
                        value={viewPlanQBBanner.planValidity}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Plan ActualPrice"
                        name="planActualPrice"
                        id="planActualPrice"
                        onChange={handleChange}
                        value={viewPlanQBBanner.planActualPrice}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBFile
                        size="lg"
                        id="planTabImage"
                        name="planTabImage"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Plan CutPrice"
                        name="planCutPrice"
                        id="planCutPrice"
                        onChange={handleChange}
                        value={viewPlanQBBanner.planCutPrice}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  {/* Sales Person and Maximum Discount */}
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="12">
                      <ReactQuill
                        type=""
                        id="planDescription"
                        name="planDescription"
                        value={viewPlanQBBanner.planDescription}
                        onChange={handleQuillChange}
                        modules={module}
                        theme="snow"
                      />
                    </MDBCol>
                  </MDBRow>
                  <hr className="mx-n2 m-0" />
                  <hr className="mx-n2 m-0" />
                  <MDBBtn className="my-4" size="lg" type="submit">
                    Submit
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Container>
  );
}
