import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBFile,
  MDBInput,
  MDBRow,
} from 'mdb-react-ui-kit';
import { Container } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Paths } from '../../../../app/baseApi/baseApi';
import axios from 'axios';
import ReactQuill from 'react-quill';
import { useEffect, useState } from 'react';

export default function EditPlanQBPrice({ editPlanQBBenefitsData, onSubmit, onEdit }) {
  const [viewPlanQBBanner, setViewPlanQBBanner] = useState(editPlanQBBenefitsData || {});
  const [imagePreview, setImagePreview] = useState(editPlanQBBenefitsData?.planTabImage || '');

  useEffect(() => {
    setViewPlanQBBanner(editPlanQBBenefitsData || {});
    setImagePreview(editPlanQBBenefitsData?.planTabImage || '');
  }, [editPlanQBBenefitsData]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      const file = files[0];
      setViewPlanQBBanner({ ...viewPlanQBBanner, [name]: file });
      setImagePreview(URL.createObjectURL(file));
    } else {
      setViewPlanQBBanner({ ...viewPlanQBBanner, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editPlanQBBenefitsData) {
      onEdit(viewPlanQBBanner);
    } else {
      onSubmit(viewPlanQBBanner);
    }
  };

  const handleQuillChange = (value) => {
    setViewPlanQBBanner((prevData) => ({ ...prevData, planDescription: value }));
  };
  // Function to strip HTML tags using DOMParser
  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || '';
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['clean'],
  ];

  const module = {
    toolbar: toolbarOptions,
  };

  return (
    <Container>
      <MDBContainer fluid>
        <MDBRow
          className="d-flex justify-content-center align-items-center"
          style={{ width: '900px' }}
        >
          <MDBCol lg="12" className="my-2">
            <h3 className="text-black mt-0 text-center">Update Plan QB Price</h3>
            <form onSubmit={handleSubmit}>
              <MDBCard>
                <MDBCardBody className="px-4">
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Plan Name"
                        name="planName"
                        id="planName"
                        onChange={handleChange}
                        value={viewPlanQBBanner.planName}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Tab Paragraph"
                        name="planTabParagraph"
                        id="planTabParagraph"
                        onChange={handleChange}
                        value={viewPlanQBBanner.planTabParagraph}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Tab Heading"
                        name="planTabHeading"
                        id="planTabHeading"
                        onChange={handleChange}
                        value={viewPlanQBBanner.planTabHeading}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Tab SubHeading"
                        name="planTabSubHeading"
                        id="planTabSubHeading"
                        onChange={handleChange}
                        value={viewPlanQBBanner.planTabSubHeading}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Plan Validity"
                        name="planValidity"
                        id="planValidity"
                        onChange={handleChange}
                        value={viewPlanQBBanner.planValidity}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Plan ActualPrice"
                        name="planActualPrice"
                        id="planActualPrice"
                        onChange={handleChange}
                        value={viewPlanQBBanner.planActualPrice}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBFile
                        name="planTabImage"
                        type="file"
                        size="lg"
                        accept="image/*"
                        onChange={handleChange}
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Plan CutPrice"
                        name="planCutPrice"
                        id="planCutPrice"
                        onChange={handleChange}
                        value={viewPlanQBBanner.planCutPrice}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  {/* Sales Person and Maximum Discount */}
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="12">
                      <ReactQuill
                        type=""
                        id="planDescription"
                        name="planDescription"
                        value={viewPlanQBBanner.planDescription}
                        onChange={handleQuillChange}
                        modules={module}
                        theme="snow"
                      />
                    </MDBCol>
                  </MDBRow>
                  <hr className="mx-n2 m-0" />
                  <hr className="mx-n2 m-0" />
                  <MDBBtn className="my-4" size="lg" type="submit">
                    Update
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Container>
  );
}
