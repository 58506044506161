import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBFile,
  MDBInput,
  MDBRow,
} from 'mdb-react-ui-kit';
import { Container } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Paths } from '../../../../app/baseApi/baseApi';
import axios from 'axios';
import { useState } from 'react';

export default function UploadPlanQBBenefits() {
  const [viewPlanQBBanner, setViewPlanQBBanner] = useState({
    statsTitle: '',
    statsParagraph: '',
    statsIcon: null,
  });
  const [isError, setIsError] = useState({});
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setViewPlanQBBanner({ ...viewPlanQBBanner, statsIcon: file });
    setIsError({ ...isError, statsIcon: '' });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setViewPlanQBBanner({ ...viewPlanQBBanner, [name]: value });
    setIsError({ ...isError, [name]: '' });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    if (!viewPlanQBBanner.statsTitle.trim()) {
      newErrors.statsTitle = 'Plan name is required';
      isValid = false;
    }
    setIsError(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append('statsTitle', viewPlanQBBanner.statsTitle);
      formData.append('statsParagraph', viewPlanQBBanner.statsParagraph);
      formData.append('statsIcon', viewPlanQBBanner.statsIcon);
      try {
        const response = await Paths.EndpointsURL.GetPlanQBBenefits;
        const record = await axios.post(response, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setViewPlanQBBanner(record.data.data);
        toast.success(record.data.message, { position: 'top-right' });
        window.location.reload();
        setViewPlanQBBanner({
          planName: '',
          planSubheading: '',
          statsIcon: null,
        });
        setIsError({});
      } catch (error) {
        console.error('Error uploading data:', error);
        // Handle error, show toast, etc.
      }
    }
  };

  return (
    <Container>
      <MDBContainer fluid>
        <MDBRow
          className="d-flex justify-content-center align-items-center"
          style={{ width: '900px' }}
        >
          <MDBCol lg="12" className="my-2">
            <h3 className="text-black mt-0 text-center">Plan QB Benefits</h3>
            <form onSubmit={handleSubmit}>
              <MDBCard>
                <MDBCardBody className="px-4">
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Benefits Title"
                        name="statsTitle"
                        id="statsTitle"
                        onChange={handleChange}
                        value={viewPlanQBBanner.statsTitle}
                        size="lg"
                        type="text"
                      />
                      {isError.statsTitle && (
                        <div className="text-danger">{isError.statsTitle}</div>
                      )}
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Benefits Paragraph"
                        name="statsParagraph"
                        id="statsParagraph"
                        onChange={handleChange}
                        value={viewPlanQBBanner.statsParagraph}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  {/* Sales Person and Maximum Discount */}
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="12">
                      <MDBFile
                        size="lg"
                        id="statsIcon"
                        name="statsIcon"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </MDBCol>
                  </MDBRow>
                  <hr className="mx-n2 m-0" />
                  <hr className="mx-n2 m-0" />
                  <MDBBtn className="my-4" size="lg" type="submit">
                    Submit
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Container>
  );
}
