import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { styled, TablePagination } from '@mui/material';
import { useState, useEffect } from 'react';
import PageHeader from 'app/PageHeader';
import Link from '@mui/material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import Button from 'react-bootstrap/Button';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Paths } from '../../../../app/baseApi/baseApi';
import Swal from 'sweetalert2';
import EditPlanQBBenefitsModel from './EditPlanQBBenefitsModel';
import UploadPlanQBBenefitsModel from './UploadPlanQBBenefitsModel';

const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const PlanQBBenefits = () => {
  const [successStories, setSuccessStories] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [isError, setIsError] = useState('');
  const [isDelete, setIsDelete] = useState('');
  const [selectedBanner, setSelectedBanner] = useState(null);

  const handleEdit = (id) => {
    setSelectedBanner(id);
    setModalShow1(true);
  };

  const displayStories = async () => {
    try {
      const response = await Paths.EndpointsURL.GetPlanQBBenefits;
      axios({
        url: response,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }).then((record) => {
        setSuccessStories(record.data.data);
        // console.log(record.data.data);
      });
    } catch (error) {
      setSuccessStories(error.msg);
      // console.log(error.msg);
    }
  };

  const handleDelete = async (id) => {
    const confirmed = await confirmDelete();
    displayStories();
    if (confirmed) {
      try {
        const deleteResp = Paths.EndpointsURL.GetPlanQBBenefits + `/${id}`;
        const res = await axios.delete(deleteResp);
        if (res.data.success && res.data.statusCode === 200) {
          handleDeleteResponse(res);
          window.location.reload();
        } else {
          toast.error(res.data.message || 'Error deleting coupon', { position: 'top-right' });
        }
      } catch (error) {
        handleError(error);
      }
    } else {
    }
  };

  const confirmDelete = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are You Sure?',
      text: 'Are you sure that you want to delete this coupon?',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    });
    return isConfirmed;
  };

  const handleDeleteResponse = (res) => {
    toast.success('Record deleted successfully', { position: 'top-right' });
    window.location.reload();
    setIsDelete(true);
    // console.log('Coupon deleted:', res.data);
  };

  const handleError = (error) => {
    console.error('Error deleting coupon:', error.message);
    // toast.error('Error deleting coupon', { position: 'top-right' });
  };

  useEffect(() => {
    displayStories();
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Container>
      <PageHeader
        title="Plan QB Benefits"
        subTitle="Plan QB Benefits"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
      />
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Add New
      </Button>
      <UploadPlanQBBenefitsModel show={modalShow} onHide={() => setModalShow(false)} />
      <EditPlanQBBenefitsModel
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        editPlanQBBenefitsData={selectedBanner}
      />
      <MDBTable align="middle">
        <MDBTableHead>
          <tr>
            <th scope="col">Benefits Title</th>
            <th scope="col">Benefits icon</th>
            <th scope="col">Benefits Paragraph</th>
            <th scope="col">Actions</th>
          </tr>
        </MDBTableHead>
        {successStories
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((result, index) => {
            return (
              <>
                <MDBTableBody>
                  <tr>
                    <td>
                      <p className="fw-normal mb-1">{result.statsTitle}</p>
                    </td>
                    <td>
                      <img
                        src={result.statsIcon}
                        alt=""
                        style={{ width: '45px', height: '45px' }}
                        className="rounded-circle"
                      />
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{result.statsParagraph}</p>
                    </td>
                    <td>
                      <Link style={{ cursor: 'pointer' }} onClick={() => handleEdit(result)}>
                        <EditIcon />
                      </Link>
                      <Link
                        style={{ color: 'red', cursor: 'pointer' }}
                        onClick={() => handleDelete(result._id)}
                      >
                        <DeleteIcon />
                      </Link>
                    </td>
                  </tr>
                </MDBTableBody>
              </>
            );
          })}
      </MDBTable>
      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={successStories.length}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 15]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Container>
  );
};

export default PlanQBBenefits;
