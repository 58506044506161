import Modal from 'react-bootstrap/Modal';
import UploadQBBanner from './UploadQBBanner';
const UploadQBBannerModel = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <UploadQBBanner />
      </Modal>
    </>
  );
};

export default UploadQBBannerModel;
