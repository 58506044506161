import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBFile,
  MDBInput,
  MDBRow,
} from 'mdb-react-ui-kit';
import { Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';

export default function EditPlanQBBanner({ editPlanQBData, onSubmit, onEdit }) {
  const [editPlanQBBanner, setEditPlanQBBanner] = useState(editPlanQBData || {});
  const [imagePreview, setImagePreview] = useState(editPlanQBData?.planImages || '');

  useEffect(() => {
    setEditPlanQBBanner(editPlanQBData || {});
    setImagePreview(editPlanQBData?.planImages || '');
  }, [editPlanQBData]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      const file = files[0];
      setEditPlanQBBanner({ ...editPlanQBBanner, [name]: file });
      setImagePreview(URL.createObjectURL(file));
    } else {
      setEditPlanQBBanner({ ...editPlanQBBanner, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editPlanQBData) {
      onEdit(editPlanQBBanner);
    } else {
      onSubmit(editPlanQBBanner);
    }
  };
  return (
    <Container>
      <MDBContainer fluid>
        <MDBRow
          className="d-flex justify-content-center align-items-center"
          style={{ width: '900px' }}
        >
          <MDBCol lg="12" className="my-2">
            <h3 className="text-black mt-0 text-center">Update QB Banner</h3>
            <form onSubmit={handleSubmit}>
              <MDBCard>
                <MDBCardBody className="px-4">
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Plan Name"
                        name="planName"
                        id="planName"
                        value={editPlanQBBanner.planName}
                        size="lg"
                        type="text"
                        onChange={handleInputChange}
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Plan Subheading"
                        name="planSubheading"
                        id="planSubheading"
                        onChange={handleInputChange}
                        value={editPlanQBBanner.planSubheading}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  {/* Sales Person and Maximum Discount */}
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBFile
                        size="lg"
                        id="planImages"
                        name="planImages"
                        accept="image/*"
                        onChange={handleInputChange}
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Plan TagLine"
                        name="planTagLine"
                        id="planTagLine"
                        onChange={handleInputChange}
                        value={editPlanQBBanner.planTagLine}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />

                  <hr className="mx-n2 m-0" />
                  <MDBBtn className="my-4" size="lg" type="submit">
                    Submit
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Container>
  );
}
