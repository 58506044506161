const base = '/jsx';
//const baseURL = 'http://localhost:7000/api/v1/';
const baseURL = 'https://web.nnlone.com/api/v1/';
const baseRoute = '/';
const imageURL = 'https://images.com';

export const Paths = {
  Home: base,
  LearningPath: baseRoute + 'Learning',
  EndpointsURL: {
    HomeProudNextians: baseURL + 'all/nextians',
    PostProudNextians: baseURL + 'nextians',
    DeleteProudNextians: baseURL + 'nextians/',
    HomeTopForm: baseURL + 'all/form',
    PlanZeroForm: baseURL + 'all/zero-query/form',
    DeletePlanZeroForm: baseURL + 'zero-query/form/',
    PlanMLBPRoForm: baseURL + 'all/mlb-pro/form',
    DeletePlanMLBPRoForm: baseURL + 'mlb-pro/form/',
    PlanCPlusForm: baseURL + 'all/c-plus/form',
    DeletePlanCPlusForm: baseURL + 'c-plus/form/',
    PlanUGForm: baseURL + 'all/plan-ug/form',
    DeletePlanUGForm: baseURL + 'plan-ug/form/',
    PlanMLBLiteForm: baseURL + 'all/mlb-lite/form',
    DeletePlanMLBLiteForm: baseURL + 'mlb-lite/form/',
    PlanAForm: baseURL + 'all/plan-a/form',
    DeletePlanAForm: baseURL + 'plan-a/form/',
    PlanMScForm: baseURL + 'all/plan-msc/form',
    DeletePlanMScForm: baseURL + 'plan-msc/form/',
    PlanNNLVSLForm: baseURL + 'all/plan-nnl/form',
    DeletePlanNNLVSLForm: baseURL + 'plan-nnl/form/',
    PlanTHForm: baseURL + 'all/plan-th/form',
    DeletePlanTHForm: baseURL + 'plan-th/form/',
    DeleteNextians: baseURL + 'nextians/',
    EditNextians: baseURL + 'nextians/',
    DeleteForm: baseURL + 'form/',
    HomeShareFeedback: baseURL + 'all/feedback',
    CoursesForm: baseURL + 'all/course/form',
    DeleteCoursesForm: baseURL + 'course/form/',
    HiringForm: baseURL + 'all/hiring/form',
    HelpSupport: baseURL + 'all/query/form',
    DeleteHelpSupport: baseURL + 'query/form/',
    OffersForm: baseURL + 'all/query/form',
    DeleteOffersForm: baseURL + 'query/form/',
    AboutFeedbackForm: baseURL + 'all/about/feedback',
    DeleteAboutFeedbackForm: baseURL + 'about/feedback/',
    DeleteShareFeedback: baseURL + 'feedback/',
    HomeFormBanner: baseURL + 'all/home/banner',
    AboutUsBanner: baseURL + 'all/about-us/banner',
    PostAboutUsBanner: baseURL + 'about-us/banner',
    DeleteAboutUsBanner: baseURL + 'about-us/banner/',
    EditAboutUsBanner: baseURL + 'about-us/banner/',
    OffersBanner: baseURL + 'all/offers/banner/',
    PostOffersBanner: baseURL + 'offers/banner/',
    EditOffersBanner: baseURL + 'offers/banner/',
    DeleteOffersBanner: baseURL + 'offers/banner/',
    MonthsOffers: baseURL + 'all/months/offers/',
    DeleteMonthsOffers: baseURL + 'months/offers/',
    PostMonthsOffers: baseURL + 'months/offers/',
    EditMonthsOffers: baseURL + 'months/offers/',
    AllCoursesBanner: baseURL + 'all/courses/banner',
    PostCoursesBanner: baseURL + 'courses/banner/',
    EditAllCoursesBanner: baseURL + 'courses/banner/',
    DeleteCoursesBanner: baseURL + 'courses/banner/',
    HomeCourses: baseURL + 'all/courses/',
    PostHomeCourses: baseURL + 'courses/',
    EditHomeCourses: baseURL + 'courses/',
    DeleteHomeCourses: baseURL + 'courses/',
    NNLCourses: baseURL + 'all/course/plan-a',
    PostNNLCourses: baseURL + 'course/plan-a',
    DeleteCourses: baseURL + 'course/plan-a/',
    HomeNNLCourses: baseURL + 'home/all/course/plan-a',
    PostHomeNNLCourses: baseURL + 'home/course/plan-a',
    PostHomeBanner: baseURL + 'home/banner',
    DeleteHomeBanner: baseURL + 'home/banner/',
    EditHomeBanner: baseURL + 'home/banner/',
    HomeMasterMind: baseURL + 'all/mastermind/',
    PostMasterMind: baseURL + 'mastermind/',
    EditMasterMind: baseURL + 'mastermind/',
    DeleteMasterMind: baseURL + 'mastermind/',
    VideoSnippts: baseURL + 'all/video/snippts',
    PostVideoSnippts: baseURL + 'video/snippts/',
    EditVideoSnippts: baseURL + 'video/snippts/',
    DeleteVideoSnippts: baseURL + 'video/snippts/',
    PostVacancyVideo: baseURL + 'vacancy-video/',
    DeleteVacancyVideo: baseURL + 'vacancy-video/',
    UpdateVacancyVideo: baseURL + 'vacancy-video/',
    GetVacancyVideo: baseURL + 'vacancy-video/',
    HomeBlogs: baseURL + 'all/blogs',
    PostBlogs: baseURL + 'blogs/',
    EditBlogs: baseURL + 'blogs/',
    DeleteBlogs: baseURL + 'blogs/',
    HomeStories: baseURL + 'all/success/stories',
    PostStories: baseURL + 'success/stories',
    EditStories: baseURL + 'success/stories/',
    DeleteStories: baseURL + 'success/stories/',
    HomeFAQ: baseURL + 'all/faq',
    PostFAQ: baseURL + 'faq',
    EditFAQ: baseURL + 'faq/',
    DeleteFAQ: baseURL + '/faq/',
    ShowExams: baseURL + 'all/exams',
    PostExams: baseURL + 'exams/',
    EditExams: baseURL + 'exams/',
    DeleteExams: baseURL + '/exams/',
    PlanZeroPriceTab: baseURL + 'all/plan/zero/price',
    PostPlanZeroPriceTab: baseURL + 'plan/zero/price/',
    EditPlanZeroPriceTab: baseURL + 'plan/zero/price/',
    DeletePlanZeroPriceTab: baseURL + 'plan/zero/price/',
    PlanZeroBanner: baseURL + 'all/plan-zero/banner',
    PostPlanZeroBanner: baseURL + 'plan-zero/banner/',
    EditPlanZeroBanner: baseURL + 'plan-zero/banner/',
    DeletePlanZeroBanner: baseURL + 'plan-zero/banner/',
    PlanZeroBenefits: baseURL + 'all/plan/zero/stats',
    PostPlanZeroBenefits: baseURL + 'plan/zero/stats/',
    EditPlanZeroBenefits: baseURL + 'plan/zero/stats/',
    DeletePlanZeroBenefits: baseURL + 'plan/zero/stats/',
    PlanZeroSalient: baseURL + 'all/plan/zero/salient',
    PostPlanZeroSalient: baseURL + 'plan/zero/salient/',
    EditPlanZeroSalient: baseURL + 'plan/zero/salient/',
    DeletePlanZeroSalient: baseURL + 'plan/zero/salient/',
    PlanZeroVideo: baseURL + 'all/zero/video',
    PostPlanZeroVideo: baseURL + 'zero/video/',
    EditPlanZeroVideo: baseURL + 'zero/video/',
    DeletePlanZeroVideo: baseURL + 'zero/video/',
    MLBPROVideo: baseURL + 'all/mlb-pro/video',
    PostMLBPROVideo: baseURL + 'mlb-pro/video/',
    EditMLBPROVideo: baseURL + 'mlb-pro/video/',
    DeleteMLBPROVideo: baseURL + 'mlb-pro/video/',
    MLBPROPriceTab: baseURL + 'all/mlb-pro/price/',
    PostMLBPROPriceTab: baseURL + 'mlb-pro/price/',
    EditMLBPROPriceTab: baseURL + 'mlb-pro/price/',
    DeleteMLBPROPriceTab: baseURL + 'mlb-pro/price/',
    WhyChooseUs: baseURL + 'all/why/choose/',
    PostWhyChooseUs: baseURL + 'why/choose/',
    EditWhyChooseUs: baseURL + 'why/choose/',
    DeleteWhyChooseUs: baseURL + 'why/choose/',
    AllExamsBanner: baseURL + 'all/exams/banner',
    PostAllExamsBanner: baseURL + 'exams/banner/',
    EditAllExamsBanner: baseURL + 'exams/banner/',
    DeleteAllExamsBanner: baseURL + 'exams/banner/',
    SuccessStoriesBanner: baseURL + 'all/stories/banner',
    PostSuccessStoriesBanner: baseURL + 'stories/banner/',
    EditSuccessStoriesBanner: baseURL + 'stories/banner/',
    DeleteSuccessStoriesBanner: baseURL + 'stories/banner/',
    NewsRoomBanner: baseURL + 'all/newsroom/banner',
    PostNewsRoomBanner: baseURL + 'newsroom/banner/',
    EditNewsRoomBanner: baseURL + 'newsroom/banner/',
    DeleteNewsRoomBanner: baseURL + 'newsroom/banner/',
    BlogsBanner: baseURL + 'all/blogs/banner',
    PostBlogsBanner: baseURL + 'blogs/banner/',
    EditBlogsBanner: baseURL + 'blogs/banner/',
    DeleteBlogsBanner: baseURL + 'blogs/banner/',
    FacultyBanner: baseURL + 'all/mastermind/banner/',
    PostFacultyBanner: baseURL + 'mastermind/banner/',
    EditFacultyBanner: baseURL + 'mastermind/banner/',
    DeleteFacultyBanner: baseURL + 'mastermind/banner/',
    NextiansBanner: baseURL + 'all/nextians/banner/',
    PostNextiansBanner: baseURL + 'nextians/banner/',
    EditNextiansBanner: baseURL + 'nextians/banner/',
    DeleteNextiansBanner: baseURL + 'nextians/banner/',
    NORCETBanner: baseURL + 'all/norcet/banner',
    PostNORCETBanner: baseURL + 'norcet/banner/',
    EditNORCETBanner: baseURL + 'norcet/banner/',
    DeleteNORCETBanner: baseURL + 'norcet/banner/',
    NursingOfficerBanner: baseURL + 'all/pgimer/banner/',
    PostNursingOfficerBanner: baseURL + 'pgimer/banner/',
    EditNursingOfficerBanner: baseURL + 'pgimer/banner/',
    DeleteNursingOfficerBanner: baseURL + 'pgimer/banner/',
    ExamMScBanner: baseURL + 'all/esic/banner/',
    PostExamMScBanner: baseURL + 'esic/banner/',
    EditExamMScBanner: baseURL + 'esic/banner/',
    DeleteExamMScBanner: baseURL + 'esic/banner/',
    ExamNCLEXBanner: baseURL + 'all/rrb/banner/',
    PostExamNCLEXBanner: baseURL + 'rrb/banner/',
    EditExamNCLEXBanner: baseURL + 'rrb/banner/',
    DeleteExamNCLEXBanner: baseURL + 'rrb/banner/',
    CHOBanner: baseURL + 'all/cho/banner/',
    PostCHOBanner: baseURL + 'cho/banner/',
    EditCHOBanner: baseURL + 'cho/banner/',
    DeleteCHOBanner: baseURL + 'cho/banner/',
    MLBProBanner: baseURL + 'all/plan-mlb-pro/banner/',
    PostMLBProBanner: baseURL + 'plan-mlb-pro/banner/',
    EditMLBProBanner: baseURL + 'plan-mlb-pro/banner/',
    DeleteMLBProBanner: baseURL + 'plan-mlb-pro/banner/',
    CPlusBanner: baseURL + 'all/plan-c/banner/',
    PostCPlusBanner: baseURL + 'plan-c/banner/',
    EditCPlusBanner: baseURL + 'plan-c/banner/',
    DeleteCPlusBanner: baseURL + 'plan-c/banner/',
    CPlusVideos: baseURL + 'all/c-plus/video/',
    PostCPlusVideos: baseURL + 'c-plus/video/',
    EditCPlusVideos: baseURL + 'c-plus/video/',
    DeleteCPlusVideos: baseURL + 'c-plus/video/',
    PlanUGBanner: baseURL + 'all/plan-ug/banner',
    DeletePlanUGBanner: baseURL + 'plan-ug/banner/',
    PostPlanUGBanner: baseURL + 'plan-ug/banner/',
    EditPlanUGBanner: baseURL + 'plan-ug/banner/',
    PlanUGVideos: baseURL + 'all/ug/video/',
    PostPlanUGVideos: baseURL + 'ug/video/',
    EditPlanUGVideos: baseURL + 'ug/video/',
    DeletePlanUGVideos: baseURL + 'ug/video/',
    PlanMLBLiteVideo: baseURL + 'all/mlb-lite/video/',
    PostPlanMLBLiteVideo: baseURL + 'mlb-lite/video/',
    EditPlanMLBLiteVideo: baseURL + 'mlb-lite/video/',
    DeletePlanMLBLiteVideo: baseURL + 'mlb-lite/video/',
    PlanAVideo: baseURL + 'all/plan-a/video/',
    PostPlanAVideo: baseURL + 'plan-a/video/',
    EditPlanAVideo: baseURL + 'plan-a/video/',
    DeletePlanAVideo: baseURL + 'plan-a/video/',
    PlanMScVideo: baseURL + 'all/msc/video/',
    PostPlanMScVideo: baseURL + 'msc/video/',
    EditPlanMScVideo: baseURL + 'msc/video/',
    DeletePlanMScVideo: baseURL + 'msc/video/',
    PlanNNLVideo: baseURL + 'all/nnl-vsl/video/',
    PostPlanNNLVideo: baseURL + 'nnl-vsl/video/',
    EditPlanNNLVideo: baseURL + 'nnl-vsl/video/',
    DeletePlanNNLVideo: baseURL + 'nnl-vsl/video/',
    PlanTHVideo: baseURL + 'all/Plan-th/video/',
    PostPlanTHVideo: baseURL + 'Plan-th/video/',
    EditPlanTHVideo: baseURL + 'Plan-th/video/',
    DeletePlanTHVideo: baseURL + 'Plan-th/video/',
    PlanMLBProBenefits: baseURL + 'all/mlb-pro/stats/',
    MLBProSalient: baseURL + 'all/mlb-pro/salient/',
    PostMLBProSalient: baseURL + 'mlb-pro/salient/',
    EditMLBProSalient: baseURL + 'mlb-pro/salient/',
    DeleteMLBProSalient: baseURL + 'mlb-pro/salient/',
    PostPlanMLBProBenefits: baseURL + 'mlb-pro/stats/',
    EditPlanMLBProBenefits: baseURL + 'mlb-pro/stats/',
    DeletePlanMLBProBenefits: baseURL + 'mlb-pro/stats/',
    PlanCPlusBenefits: baseURL + 'all/c-plus/stats/',
    PlanCPlusSalient: baseURL + 'all/c-plus/salient/',
    PostPlanCPlusSalient: baseURL + 'c-plus/salient/',
    EditPlanCPlusSalient: baseURL + 'c-plus/salient/',
    DeletePlanCPlusSalient: baseURL + 'c-plus/salient/',
    PostPlanCPlusBenefits: baseURL + 'c-plus/stats/',
    EditPlanCPlusBenefits: baseURL + 'c-plus/stats/',
    DeletePlanCPlusBenefits: baseURL + 'c-plus/stats/',
    PlanCPlusTabPrice: baseURL + 'all/c-plus/price/',
    PostPlanCPlusTabPrice: baseURL + 'c-plus/price/',
    EditPlanCPlusTabPrice: baseURL + 'c-plus/price/',
    DeletePlanCPlusTabPrice: baseURL + 'c-plus/price/',
    PlanUGBenefits: baseURL + 'all/ug/stats/',
    PostPlanUGBenefits: baseURL + 'ug/stats/',
    EditPlanUGBenefits: baseURL + 'ug/stats/',
    DeletePlanUGBenefits: baseURL + 'ug/stats/',
    PlanUGSalient: baseURL + 'all/ug/salient/',
    PostPlanUGSalient: baseURL + 'ug/salient/',
    EditPlanUGSalient: baseURL + 'ug/salient/',
    DeletePlanUGSalient: baseURL + 'ug/salient/',
    PlanUGTabPrice: baseURL + 'all/ug/price/',
    PostPlanUGTabPrice: baseURL + 'ug/price/',
    EditPlanUGTabPrice: baseURL + 'ug/price/',
    DeletePlanUGTabPrice: baseURL + 'ug/price/',
    MLBLiteBenefits: baseURL + 'all/mlb-lite/stats/',
    PostMLBLiteBenefits: baseURL + 'mlb-lite/stats/',
    EditMLBLiteBenefits: baseURL + 'mlb-lite/stats/',
    DeleteMLBLiteBenefits: baseURL + 'mlb-lite/stats/',
    MLBLiteBanner: baseURL + 'all/plan-mlb-lite/banner',
    DeleteMLBLiteBanner: baseURL + 'plan-mlb-lite/banner/',
    PostMLBLiteBanner: baseURL + 'plan-mlb-lite/banner/',
    EditMLBLiteBanner: baseURL + 'plan-mlb-lite/banner/',
    MLBLiteSalient: baseURL + 'all/mlb-lite/salient/',
    PostMLBLiteSalient: baseURL + 'mlb-lite/salient/',
    EditMLBLiteSalient: baseURL + 'mlb-lite/salient/',
    DeleteMLBLiteSalient: baseURL + 'mlb-lite/salient/',
    MLBLiteTabPrice: baseURL + 'all/mlb-lite/price/',
    PostMLBLiteTabPrice: baseURL + 'mlb-lite/price/',
    EditMLBLiteTabPrice: baseURL + 'mlb-lite/price/',
    DeleteMLBLiteTabPrice: baseURL + 'mlb-lite/price/',
    PlanABanner: baseURL + 'all/plan-a/banner/',
    PostPlanABanner: baseURL + 'plan-a/banner/',
    EditPlanABanner: baseURL + 'plan-a/banner/',
    DeletePlanABanner: baseURL + 'plan-a/banner/',
    PlanABenefits: baseURL + 'all/plan-a/stats/',
    PostPlanABenefits: baseURL + 'plan-a/stats/',
    EditPlanABenefits: baseURL + 'plan-a/stats/',
    DeletePlanABenefits: baseURL + 'plan-a/stats/',
    PlanASalient: baseURL + 'all/plan-a/salient/',
    PostPlanASalient: baseURL + 'plan-a/salient/',
    EditPlanASalient: baseURL + 'plan-a/salient/',
    DeletePlanASalient: baseURL + 'plan-a/salient/',
    PlanATabPrice: baseURL + 'all/plan-a/price/',
    PostPlanATabPrice: baseURL + 'plan-a/price/',
    EditPlanATabPrice: baseURL + 'plan-a/price/',
    DeletePlanATabPrice: baseURL + 'plan-a/price/',
    PlanMScBenefits: baseURL + 'all/msc/stats/',
    PlanMScBanner: baseURL + 'all/plan-msc/banner/',
    PostPlanMScBanner: baseURL + 'plan-msc/banner/',
    EditPlanMScBanner: baseURL + 'plan-msc/banner/',
    DeletePlanMScBanner: baseURL + 'plan-msc/banner/',
    PostPlanMScBenefits: baseURL + 'msc/stats/',
    EditPlanMScBenefits: baseURL + 'msc/stats/',
    DeletePlanMScBenefits: baseURL + 'msc/stats/',
    PlanMScSalient: baseURL + 'all/msc/salient/',
    PostPlanMScSalient: baseURL + 'msc/salient/',
    EditPlanMScSalient: baseURL + 'msc/salient/',
    DeletePlanMScSalient: baseURL + 'msc/salient/',
    PlanMScTabPrice: baseURL + 'all/msc/price/',
    PostPlanMScTabPrice: baseURL + 'msc/price/',
    EditPlanMScTabPrice: baseURL + 'msc/price/',
    DeletePlanMScTabPrice: baseURL + 'msc/price/',
    PlanNNLBenefits: baseURL + 'all/nnl-vsl/stats/',
    PlanNNLBanner: baseURL + 'all/plan-nnl-vsl/banner/',
    PostPlanNNLBanner: baseURL + 'plan-nnl-vsl/banner/',
    EditPlanNNLBanner: baseURL + 'plan-nnl-vsl/banner/',
    DeletePlanNNLBanner: baseURL + 'plan-nnl-vsl/banner/',
    PostPlanNNLBenefits: baseURL + 'nnl-vsl/stats/',
    EditPlanNNLBenefits: baseURL + 'nnl-vsl/stats/',
    DeletePlanNNLBenefits: baseURL + 'nnl-vsl/stats/',
    PlanNNLSalient: baseURL + 'all/nnl-vsl/salient/',
    PostPlanNNLSalient: baseURL + 'nnl-vsl/salient/',
    EditPlanNNLSalient: baseURL + 'nnl-vsl/salient/',
    DeletePlanNNLSalient: baseURL + 'nnl-vsl/salient/',
    PlanNNLTabPrice: baseURL + 'all/nnl-vsl/price/',
    PostPlanNNLTabPrice: baseURL + 'nnl-vsl/price/',
    EditPlanNNLTabPrice: baseURL + 'nnl-vsl/price/',
    DeletePlanNNLTabPrice: baseURL + 'nnl-vsl/price/',
    PlanTHBenefits: baseURL + 'all/plan-th/stats/',
    PostPlanTHBenefits: baseURL + 'plan-th/stats/',
    EditPlanTHBenefits: baseURL + 'plan-th/stats/',
    DeletePlanTHBenefits: baseURL + 'plan-th/stats/',
    PlanTHSalient: baseURL + 'all/plan-th/salient/',
    PlanTHBanner: baseURL + 'all/plan-th/banner/',
    PostPlanTHBanner: baseURL + 'plan-th/banner/',
    EditPlanTHBanner: baseURL + 'plan-th/banner/',
    DeletePlanTHBanner: baseURL + 'plan-th/banner/',
    PostPlanTHSalient: baseURL + 'plan-th/salient/',
    EditPlanTHSalient: baseURL + 'plan-th/salient/',
    DeletePlanTHSalient: baseURL + 'plan-th/salient/',
    PlanTHTabPrice: baseURL + 'all/plan-th/price/',
    PostPlanTHTabPrice: baseURL + 'plan-th/price/',
    EditPlanTHTabPrice: baseURL + 'plan-th/price/',
    DeletePlanTHTabPrice: baseURL + 'plan-th/price/',
    RazorpayPayment: baseURL + 'razorpay-register/',
    DeleteRazorpayPayment: baseURL + 'razorpay-register/',
    CareerPageForm: baseURL + 'career-page/',
    DeleteCareerPageForm: baseURL + 'career-page/',
    PostHomePageFAQ: baseURL + 'home-faq/',
    GetHomePageFAQ: baseURL + 'all/home-faq/',
    DeleteHomePageFAQ: baseURL + 'home-faq/',
    UpdateHomePageFAQ: baseURL + 'home-faq/',
    PostPlanUGCaters: baseURL + 'caters/',
    DeletePlanUGCaters: baseURL + 'caters/',
    GetPlanUGCaters: baseURL + 'caters/',
    EditPlanUGCaters: baseURL + 'caters/',
    GetCouponCode: baseURL + 'coupon-code/',
    DeleteCouponCode: baseURL + 'coupon-code',
    EditCouponCode: baseURL + 'coupon-code/',
    GenerateCouponCode: baseURL + 'generate-coupon',
    GetPlanQBBanner: baseURL + 'plan-qb-banner/',
    GetPlanQBBenefits: baseURL + 'plan-qb-benefits/',
    GetPlanQBSalient: baseURL + 'plan-qb-salient/',
    GetPlanQBPriceTab: baseURL + 'plan-qb-price/',
  },
  imagePathURL: {
    URL: imageURL,
  },
};
